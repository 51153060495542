import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './Topbar.module.css';
import { connect } from 'react-redux';

const Topbar = () => (
  <Row className={styles.topBarRow}>
    <Col xs={12} className={styles.topMenu}>
    </Col>
  </Row>
);

export default connect()(Topbar);